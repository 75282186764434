import { Box, Flex, Heading, Text } from '@chakra-ui/react'

interface ContentSlideBannerProps {
  image: string
  title: string
  description?: string
}

export function ContentSlideBanner({
  image,
  title,
  description,
}: ContentSlideBannerProps) {
  return (
    <Flex
      w="100%"
      h="100%"
      mx="auto"
      bgImg={image}
      bgRepeat="no-repeat"
      bgSize="cover"
      align="center"
      justify="center"
    >
      <Box w={['100%', '50%']} mx={['6', '20']}>
        <Heading fontSize="5xl" mb="5" textAlign="center" color="white">
          {title}
        </Heading>

        {description && (
          <Text fontSize="md" textAlign="center" color="white">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  )
}
