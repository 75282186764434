import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { FaHome, FaBuilding, FaHammer, FaTools } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { Header } from '../components/Header'
import { WhatsAppButton } from '../components/WhatsAppButton'
import { Footer } from '../components/Footer'
import { ContentService } from '../components/ContentService'
import { Solution } from '../components/Solution'
import { ContentSlideBanner } from '../components/ContentSlideBanner'

import ImageBackResidencial from '../assets/banner-reformas-residenciais.jpg'
import ImageBackGeral from '../assets/banner-reparo-reforma-geral.jpg'
import ImageBackPitura from '../assets/banner-pintura.jpg'

const solutionsOne = [
  {
    description: 'DEMOLIÇÃO',
  },
  {
    description: 'CONSTRUÇÃO',
  },
  {
    description: 'IMPERMEABILIZAÇÃO',
  },
  {
    description: 'PISOS E PORCELANATO',
  },
  {
    description: 'GESSO E DRY WALL',
  },
]

const solutionsTwo = [
  {
    description: 'PINTURA',
  },
  {
    description: 'INSTALAÇÕES ELÉTRICAS',
  },
  {
    description: 'INSTALAÇÕES HIDRÁULICAS',
  },
  {
    description: 'AR CONDICIONADO',
  },
  {
    description: 'LIMPEZA PÓS-OBRA',
  },
]

export default function Home() {
  return (
    <>
      <Header />
      <Flex
        as="main"
        w="100%"
        justify="center"
        align="center"
        direction="column"
      >
        <WhatsAppButton />

        <Flex as="section" justify="center" align="center" w="100%">
          <Flex w="100%" height={[450, 600]} mx="auto" mt={[10, 10]}>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              spaceBetween={30}
              navigation
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide>
                <ContentSlideBanner
                  image={ImageBackResidencial}
                  title="REFORMAS RESIDENCIAIS"
                  description="Apresentamos soluções especializadas para pequenos reparos em residências, empresas e condomínios. Contamos com uma equipe de profissionais altamente capacitados, prontos para oferecer uma variedade de serviços de qualidade."
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentSlideBanner
                  image={ImageBackGeral}
                  title="REFORMAS EM GERAL"
                  description="Somos uma equipe especializada e dedicada a transformar espaços, destacando-nos como líderes no segmento de reformas. Nossa expertise abrange uma ampla gama de projetos, desde residências e apartamentos até condomínios e ambientes corporativos."
                />
              </SwiperSlide>
              <SwiperSlide>
                <ContentSlideBanner
                  image={ImageBackPitura}
                  title="PINTURA"
                  description="
                  A JF ADMINISTRAÇÃO DE SERVIÇOS assegura um acabamento impecável em serviços de pintura, tanto para residências, apartamentos, condomínios quanto para empresas, abrangendo tanto ambientes internos quanto externos."
                />
              </SwiperSlide>
            </Swiper>
          </Flex>
        </Flex>

        <Flex
          as="section"
          w="100%"
          justify="center"
          align="center"
          px="6"
          py="8"
          id="about"
        >
          <Box w="100%" my="10" maxWidth={1200}>
            <Heading
              fontSize={['2xl', '4xl']}
              textAlign="center"
              mb="6"
              color="blue.500"
              textTransform="uppercase"
            >
              JF Administração de Serviços
            </Heading>
            <Text fontSize={['md', 'xl']} align="justify">
              Atualmente, a indústria da reforma e construção civil se apresenta
              como uma realidade incontestável, permitindo que as empresas se
              desvinculem de tarefas e gestão de setores que não estão alinhados
              com sua atividade principal. Esse cenário proporciona uma melhoria
              na qualidade e uma relação custo/benefício mais favorável,
              características que somente uma empresa especializada pode
              verdadeiramente oferecer, promovendo assim a tranquilidade e
              satisfação necessárias.
            </Text>
            <Text fontSize={['md', 'xl']} align="justify" pt="3">
              Ao optar por investir em reforma e construção civil, é crucial
              adotar precauções específicas. Recomenda-se verificar a média dos
              custos praticados no mercado, uma vez que preços muito abaixo do
              padrão podem indicar serviços de qualidade inferior, comprometendo
              a estrutura, gerando atrasos e erros nos pagamentos aos
              profissionais envolvidos, fatores que naturalmente prejudicam o
              progresso da obra e a excelência dos serviços prestados.
            </Text>
            <Text fontSize={['md', 'xl']} align="justify" pt="3">
              Nossa equipe é composta por profissionais especializados na
              prestação de serviços na área da construção civil, abrangendo
              projetos industriais, comerciais e residenciais.
            </Text>
            <Text fontSize={['md', 'xl']} align="justify" pt="3">
              Nosso compromisso é oferecer conforto ambiental aos nossos
              clientes, por meio de serviços de alta qualidade no segmento de
              reforma e construção civil.
            </Text>
            <Text fontSize={['md', 'xl']} align="justify" pt="3">
              Mantemos um programa de qualidade total em todos os setores da
              empresa, garantindo nossa capacidade de atender aos clientes com o
              grau de excelência que necessitam, aliado a custos competitivos no
              âmbito da reforma e construção civil.
            </Text>
          </Box>
        </Flex>

        <Flex
          as="section"
          w="100%"
          justify="center"
          align="center"
          px="6"
          py="8"
          id="services"
          bg="gray.100"
        >
          <Box w="100%" maxWidth={1200}>
            <Heading
              fontSize={['2xl', '4xl']}
              textAlign="center"
              my="8"
              color="blue.500"
            >
              NOSSOS SERVIÇOS
            </Heading>
            <Text fontSize={['md', 'xl']} align="justify" pt="3">
              Diferentemente de empresas que prometem abranger todas as áreas e,
              na prática, falham em entregar a qualidade prometida, nosso modelo
              de gestão imobiliária foca na coordenação de um time de
              especialistas. Isso assegura que cada aspecto do projeto seja
              executado por um profissional especializado na respectiva área.
            </Text>
            <Text fontSize={['md', 'xl']} align="justify" pt="3">
              Proporcionamos maior eficiência, mantendo um padrão de qualidade
              elevado, o que se traduz em resultados superiores para a sua
              reforma.
            </Text>

            <Flex align="center" justify="center">
              <SimpleGrid flex="1" gap="4" minChildWidth="250px" my="8">
                <ContentService
                  size={250}
                  icon={FaHome}
                  title="OBRAS RESIDENCIAIS"
                  description="Gestão dedicada e eficaz à sua residência."
                />
                <ContentService
                  size={250}
                  icon={FaBuilding}
                  title="OBRAS COMERCIAIS"
                  description="Agilidade e excelência para a sua empresa."
                />
                <ContentService
                  size={250}
                  icon={FaHammer}
                  title="REFORMAS RESIDENCIAIS"
                  description="Equipe qualificada nos acabamentos e detalhes."
                />
                <ContentService
                  size={250}
                  icon={FaTools}
                  title="REFORMAS COMERCIAIS"
                  description="Dedicação extra para seu caixa não parar!"
                />
              </SimpleGrid>
            </Flex>
          </Box>
        </Flex>

        <Flex
          as="section"
          w="100%"
          justify="center"
          align="center"
          px="6"
          py="8"
          id="solutions"
        >
          <Flex
            w="100%"
            my="10"
            maxWidth={1200}
            justify="center"
            align="center"
            flexDirection="column"
          >
            <Heading
              fontSize={['2xl', '4xl']}
              textAlign="center"
              mb="6"
              color="blue.500"
              textTransform="uppercase"
            >
              Solução completa
            </Heading>
            <Text fontSize={['md', 'xl']} align="justify">
              Nossa equipe de especialistas está preparada para aprimorar e
              transformar seu espaço, garantindo não apenas uma estética
              agradável, mas também funcionalidade excepcional. Comprometemo-nos
              em cumprir todas as regulamentações locais, proporcionando uma
              reforma ou construção que otimize o retorno sobre o investimento.
            </Text>

            <Flex
              w="100%"
              maxWidth={800}
              justify="center"
              align="center"
              p="8"
              flexDirection={['column', 'row']}
            >
              <Box w="100%">
                {solutionsOne.map((solution) => (
                  <Solution
                    description={solution.description}
                    key={solution.description}
                  />
                ))}
              </Box>

              <Box w="100%">
                {solutionsTwo.map((solution) => (
                  <Solution
                    description={solution.description}
                    key={solution.description}
                  />
                ))}
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          as="section"
          w="100%"
          justify="center"
          align="center"
          px="6"
          py="8"
          bg="gray.100"
          id="why"
        >
          <Flex w="100%" maxWidth={900} flexDirection="column">
            <Heading
              fontSize={['2xl', '4xl']}
              textAlign="center"
              my="8"
              color="blue.500"
              textTransform="uppercase"
            >
              Por que escolher nossa empresa?
            </Heading>

            <SimpleGrid
              flex="1"
              gap="4"
              minChildWidth={['320px', '400px']}
              my="8"
            >
              <ContentService
                size={400}
                title="AGILIDADE NA EXECUÇÃO"
                description="Cada dia que o seu imóvel permanece inativo representa uma redução na sua receita. Por essa razão, enfocamos na entrega ágil e eficiente da sua obra, estabelecendo um cronograma previsível e realista. Nosso compromisso é assegurar que seu planejamento não seja surpreendido por atrasos inesperados."
              />
              <ContentService
                size={400}
                title="EXPERIÊNCIA COMPROVADA E GARANTIA DE QUALIDADE"
                description="Contamos com os especialistas mais qualificados do mercado para oferecer todos os serviços essenciais à sua reforma. A condução do projeto é realizada por profissionais confiáveis, especializados em todas as áreas necessárias para garantir o sucesso integral da sua reforma."
              />
              <ContentService
                size={400}
                title="CUSTO-BENEFÍCIO COMPETITIVO"
                description="Apresentamos um serviço personalizado, minuciosamente elaborado para satisfazer às suas necessidades específicas. Garantimos alta qualidade e um atendimento excepcional, tudo isso a um preço competitivo, tornando seu investimento ainda mais valioso."
              />
              <ContentService
                size={400}
                title="PLANEJAMENTO DE COMPRAS"
                description="Antes do início da obra, disponibilizamos ao cliente um cronograma contendo todas as datas necessárias para a entrega dos materiais de acabamento, tais como porcelanatos, louças e metais. Essa abordagem simplifica o planejamento das compras, proporcionando maior organização e tranquilidade ao cliente."
              />
            </SimpleGrid>
          </Flex>
        </Flex>

        <Flex
          as="section"
          w="100%"
          justify="center"
          align="center"
          px="6"
          py="8"
        >
          <Flex w="100%" maxWidth={1200} flexDirection="column">
            <Heading
              fontSize={['2xl', '4xl']}
              textAlign="center"
              my="8"
              color="blue.500"
              textTransform="uppercase"
            >
              Serviços complementares
            </Heading>

            <SimpleGrid
              flex="1"
              gap="2"
              minChildWidth={['320px', '350px']}
              my="8"
            >
              <ContentService
                size={350}
                title="PROJETOS DE ARQUITETUTA"
                description="Um escritório de arquitetura dedicado à criação de projetos de interiores inovadores e funcionais, visando transformar seu espaço em algo verdadeiramente excepcional."
              />
              <ContentService
                size={350}
                title="MARCENARIA"
                description="Disponibilizamos uma marcenaria que executa nossos projetos com um atendimento exclusivo e prazos otimizados para as nossas obras."
              />
              <ContentService
                size={350}
                title="MARMORARIA E VIDRAÇARIA"
                description="Nossos parceiros em marmoraria e vidraçaria estão perfeitamente alinhados ao nosso processo de reforma, executando nossos projetos com um padrão de acabamento superior e prazos diferenciados."
              />
            </SimpleGrid>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </>
  )
}
