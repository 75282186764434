import {
  Text,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react'

import { ActiveNavLink } from './ActiveNavLink'

interface NavLinkProps extends ChakraLinkProps {
  children: string
  href: string
}

export function NavLink({ children, href, ...rest }: NavLinkProps) {
  return (
    <ActiveNavLink to={href} href={href}>
      <ChakraLink
        _after={{
          content: `""`,
          display: 'block',
          width: 0,
          height: '2px',
          background: 'white',
          transition: 'width 0.3s',
        }}
        _hover={{
          textDecoration: 'none',
          _after: {
            width: '100%',
          },
        }}
        {...rest}
      >
        <Text color="white" fontWeight="bold">
          {children}
        </Text>
      </ChakraLink>
    </ActiveNavLink>
  )
}
