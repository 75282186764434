import { useContext } from 'react'

import { HeaderDrawerContext } from '../contexts/HeaderDrawerContext'

export function useHeaderDrawer() {
  const context = useContext(HeaderDrawerContext)

  if (!context) {
    throw new Error(
      'useHeaderDrawer must be used within a HeaderDrawerProvider',
    )
  }

  return context
}
