import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    transparent: 'transparent',
    white: '#fff',
    black: '#000',
    blue: {
      900: '#033652',
      700: '#05486C',
      500: '#0A5984',
      300: '#1E709B',
      100: '#3C80A4',
    },
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
  styles: {
    global: {
      body: {
        bg: 'white',
        color: 'black',
      },
    },
  },
})
