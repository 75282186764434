import { ReactNode } from 'react'

import { HeaderDrawerProvider } from './HeaderDrawerContext'

interface AppProviderProps {
  children: ReactNode
}

export function AppProvider({ children }: AppProviderProps) {
  return <HeaderDrawerProvider>{children}</HeaderDrawerProvider>
}
