import { Flex, Icon, Text } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'

interface SolutionProps {
  description: string
}

export function Solution({ description }: SolutionProps) {
  return (
    <Flex w="100%" align="center" gap="2">
      <Icon as={FaCheckCircle} fontSize="22" color="blue.500" />
      <Text fontSize={['md', 'xl']}>{description}</Text>
    </Flex>
  )
}
