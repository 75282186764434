import { Stack } from '@chakra-ui/react'

import { NavLink } from './NavLink'

export function HeaderNav() {
  return (
    <Stack spacing="6" direction={['column', 'row']}>
      <NavLink href="about">SOBRE A EMPRESA</NavLink>
      <NavLink href="services">SERVIÇOS</NavLink>
      <NavLink href="solutions">SOLUÇÃO COMPLETA</NavLink>
      <NavLink href="why">POR QUE?</NavLink>
      <NavLink href="contact">CONTATO</NavLink>
    </Stack>
  )
}
