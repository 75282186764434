import { Flex, Heading, Icon, Text } from '@chakra-ui/react'
import { ElementType } from 'react'

interface ContentServiceProps {
  icon?: ElementType
  title: string
  description: string
  size: number
}

export function ContentService({
  icon,
  title,
  description,
  size,
}: ContentServiceProps) {
  return (
    <Flex
      justify="center"
      align="center"
      w="100%"
      maxW={size}
      h={size}
      flexDirection="column"
      bgColor="blue.500"
      p="6"
      borderRadius="16"
      _hover={{
        shadow: '2xl',
      }}
      mx="auto"
    >
      {icon && (
        <Flex
          justify="center"
          align="center"
          w="45px"
          h="45px"
          bgColor="white"
          borderRadius="50%"
        >
          <Icon as={icon} fontSize="25" color="blue.700" />
        </Flex>
      )}

      <Heading fontSize="lg" color="white" textAlign="center" my="6">
        {title}
      </Heading>

      <Text fontSize="md" align="center" color="white">
        {description}
      </Text>
    </Flex>
  )
}
