import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react'
import { RiMenuLine } from 'react-icons/ri'

import { useHeaderDrawer } from '../../hooks/useHeaderDrawer'
import { HeaderNav } from './HeaderNav'

import LogoImg from '../../assets/logo-horizontal-removebg.png'

export function Header() {
  const { isOpen, onClose, onOpen } = useHeaderDrawer()

  const isDrawerHeader = useBreakpointValue({
    base: true,
    lg: false,
  })

  return (
    <Flex
      as="header"
      w="100%"
      h={100}
      bg="blue.500"
      justify="center"
      align="center"
      position="fixed"
      zIndex="9"
    >
      <Flex
        w="100%"
        maxWidth={1200}
        align="center"
        justify="space-between"
        px="2"
      >
        <Image
          src={LogoImg}
          alt="JF Administração de Serviços"
          w="100%"
          maxW="250px"
        />

        {isDrawerHeader ? (
          <>
            <IconButton
              aria-label="Open navigation"
              icon={<Icon as={RiMenuLine} />}
              fontSize="24"
              color="white"
              borderColor="white"
              variant="outline"
              onClick={onOpen}
              ml="auto"
              mr="5"
            ></IconButton>

            <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
              <DrawerOverlay>
                <DrawerContent bg="blue.500" p="4">
                  <DrawerCloseButton color="white" mt="6" />
                  <DrawerHeader color="white">Menu</DrawerHeader>

                  <DrawerBody>
                    <HeaderNav />
                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </>
        ) : (
          <Flex w="100%" maxWidth={1000} align="center" justify="center">
            <HeaderNav />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
