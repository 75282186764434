import { createContext, ReactNode } from 'react'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'

interface HeaderDrawerContextProps {
  children: ReactNode
}

type HeaderDrawerContextData = UseDisclosureReturn

export const HeaderDrawerContext = createContext({} as HeaderDrawerContextData)

export function HeaderDrawerProvider({ children }: HeaderDrawerContextProps) {
  const disclosure = useDisclosure()

  return (
    <HeaderDrawerContext.Provider value={disclosure}>
      {children}
    </HeaderDrawerContext.Provider>
  )
}
