import { Box, Flex, Link, Text } from '@chakra-ui/react'

export function Footer() {
  return (
    <Flex
      justify="center"
      align="center"
      w="100%"
      h={[300, 220, 150, 100]}
      bgColor="blue.900"
      color="white"
      id="contact"
    >
      <Flex w="100%" maxWidth={1280} justify="center" align="center">
        <Flex align="center" w="100%">
          <Box mx={['4', '0']}>
            <Text fontWeight="bold" textAlign={['center', 'left']}>
              Desenvolvido por:{' '}
              <Link
                target="_blank"
                href="https://lcsprojects.netlify.app/"
                color="white"
                textDecoration="underline"
                _hover={{ filter: 'brightness(0.9)' }}
              >
                LCS Projects
              </Link>
            </Text>

            <Text fontWeight="bold" textAlign={['center', 'left']}>
              © 2023 - {new Date().getFullYear()} JF Administração de Serviços.
              Todos os direitos reservados.
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
