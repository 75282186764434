/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement } from 'react'
import { Link, LinkProps } from 'react-scroll'

import { useHeaderDrawer } from '../../hooks/useHeaderDrawer'

interface ActiveNavLinkProps extends LinkProps {
  children: ReactElement
  href: string
}

export function ActiveNavLink({ children, href, ...rest }: ActiveNavLinkProps) {
  const { onClose } = useHeaderDrawer()

  return (
    <Link
      to={rest.to ? rest.to : href}
      activeClass=""
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      onClick={onClose}
    >
      {children}
    </Link>
  )
}
