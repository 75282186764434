import { ChakraProvider } from '@chakra-ui/react'

import { AppProvider } from './contexts'

import Home from './pages/Home'

import { theme } from './styles/theme'

import './styles/styles.scss'

function App() {
  return (
    <AppProvider>
      <ChakraProvider theme={theme}>
        <Home />
      </ChakraProvider>
    </AppProvider>
  )
}

export default App
